.container {
  text-align: center;
}

.welcome {
  font-family: heading;
  font-size: var(--headingSize);
}

.progress {
  height: 80px;
  width: 80px;
  display: inline-block;
  position: absolute; 
  padding: var(--spacing);
  top: 0;
  left: 0;
}

.progress svg {
  stroke: rgb(--background);
}

.clock {
  position: absolute; 
  padding: var(--spacing);
  top: 0;
  right: 0;
  font-family: heading;
  font-size: var(--fontSize);
}

.logo {
  position: absolute;
  left: 48px;
  top: 40px;
}

.timeline {
  position: absolute;
  bottom: var(--spacing);
  left: 0;
  width: 360px;
  text-align: left;
}

.bottomRight {
  position: absolute;
  right: var(--spacing);
  bottom: var(--spacing);
  font-family: heading;
  font-size: var(--fontSize);
  cursor: pointer;
}

.bottomLeft {
  position: absolute;
  left: var(--spacing);
  bottom: var(--spacing);
  font-family: heading;
  font-size: var(--fontSize);
  cursor: pointer;
}

.iframe {
  position: absolute;
}

.pressure svg {
  vertical-align: middle;
}

.conditions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--spacing);
}