.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing);
  font-size: var(--fontSize);
}

.footerLeft,
.footerRight {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.footerRight {
  padding-right: var(--spacing);
  margin-right: var(--spacing);
}

.divider {
  display: none;
}

.heading {
  font-family: heading;
}

@media (max-width: 600px) {
  .footerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing);
  }

  .divider {
    display: block;
    width: 100%;
  }
}