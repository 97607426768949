.container {
  width: var(--desktopWidth);
  margin: var(--spacing) auto;
  font-size: var(--fontSize);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing);
}

.calendar {
  color: rgb(var(--background));
}