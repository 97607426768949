.homeContainer {
  font-size: var(--fontSize);
}

.splitLayout {
  display: flex;
}

.left {
  width: var(--desktopWidth);
  margin: auto;
  font-size: var(--fontSize);
  padding-top: var(--spacing);
}

.right {
  background-image: linear-gradient(0deg, rgba(var(--background), 0.5) , rgba(var(--background), 0.5)), url(../../assets/imgs/oil.jpeg);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  flex-basis: 100%;
}

.heading {
  text-align: center;
}

.subHeading {
  height: var(--spacing);
  margin: var(--spacing) auto;
}

.card {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing);
}

.icon {
  stroke: rgba(var(--foreground));
  fill: rgba(var(--foreground));
  margin-right: var(--spacing);
  height: 30px;
}

@media screen and (max-width: 600px) {

  .right {
    display: none;
  }
  
}