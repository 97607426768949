.container {
  margin: 3rem auto;
  width: var(--desktopWidth);
  font-size: var(--fontSize);
}

.profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: var(--spacing);

  background-position: center center;
  background-size: cover;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}