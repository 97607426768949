.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing);
  height: 100%;
}

.flexContainer {
  display: flex;
  gap: var(--spacing);

  background-color: rgb(var(--foreground));
  color: rgb(var(--background));
  border-radius: var(--spacing);
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.heading {
  font-family: heading;
  font-size: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--spacing);
  margin: var(--spacing);
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

svg {
  fill: rgba(var(--background));
  stroke: rgba(var(--background));
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  gap: var(--spacing);
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  .flexContainer {
    flex-direction: column;
  }
}