:root{
  /* --foreground: 240, 222, 213; */
  --foreground: 241, 245, 249;
  --background: 17, 24, 39;
  /* --background: 0, 0, 0; */
  --headingSize: 5rem;
  --logoSize: 3rem;
  --fontSize: 1.25rem;
  --spacing: 1.5rem;
  --gap: 1rem;
  --desktopWidth: 70%;
  --narrow: 30%;
}

@media (max-width: 600px) {
  :root {
    --headingSize: 3rem;
    --fontSize: 1.25rem;
    --spacing: 1rem;
    --desktopWidth: 90%;
  }
}

@font-face {
  font-family: 'heading';
  src: url(assets/fonts/noe-display.ttf);
}

@font-face {
  font-family: 'montserrat';
  src: url(assets/fonts/Montserrat-Regular.ttf);
}


html,
body,
#root {
  background-color: rgba(var(--background));
  color: rgba(var(--foreground));
  width: 100%;
  height: 100%;
  font-family: 'montserrat', sans-serif;
  font-size: 100%;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: block;
}

.react-calendar.w-full {
  width: 100%;
}

a {
  text-decoration: none;
  color: rgba(var(--foreground));
}

.padding {
  padding: var(--spacing);
}

.margin {
  margin: var(--spacing);
}