.gridContainer {
  width: var(--desktopWidth);
  margin: var(--spacing) auto;
  column-count: 3;
  font-size: var(--fontSize);
}

.gridContainerVertical {
  font-size: var(--fontSize);
  margin: auto;
  width: 800px;
}

.gridItemVertical {
  width: 800px;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin: var(--spacing);
  padding: var(--spacing);
  background-position: center bottom;
  background-size: cover;
}

.gridItem {
  break-inside: avoid;
  padding: var(--spacing);
  border: 1px solid rgba(var(--foreground));
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(var(--foreground));
  color: rgba(var(--background));
  margin-bottom: var(--spacing);
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.avatar {
  margin-top: var(--spacing);
}

.avatar img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing);
}

.reviewHeader span {
  font-size: medium;
}

.stars {
  background-image: url(../../assets/imgs/enabled-star.svg);
  height: 25px;
  width: 120px;
}

@media screen and (max-width: 600px) {
  .gridContainer {
    column-count: 1;
  }
  
}

.logoContainer {
  text-align: center;
  margin: var(--spacing);
  display: flex;
  flex-direction: column;
  font-family: heading;
  gap: var(--gap);
}

.logoContainer svg{
  fill: rgba(var(--background));
  stroke: rgba(var(--background));
  height: var(--logoSize);
}

.whiteBackground {
  background-color: rgba(var(--foreground));
}