.container {
  width: var(--desktopWidth);
  margin: var(--spacing) auto;
  font-size: var(--fontSize);
  padding-top: var(--spacing);
}

.shapeless {
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
}

.shapeless:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.subheading {
  text-align: center;
  margin-bottom: var(--spacing);
}

.padding {
  padding: var(--spacing);
}

.margin {
  margin: var(--spacing);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--spacing);
  row-gap: var(--spacing);
  margin: var(--spacing) auto;
}

.gridItem {
  border-radius: 10px;
  cursor: pointer;
  color: rgba(var(--foreground));
  margin-bottom: var(--spacing);
  display: flex;
  gap: var(--spacing);
  align-items: center;
}

.reverseGridItem {
  flex-direction: row-reverse;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
  color: rgba(var(--foreground));
  margin-bottom: var(--spacing);
  display: flex;
  gap: var(--spacing);
  align-items: center;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--spacing);
  row-gap: var(--spacing);
  margin: var(--spacing) auto;
}

.buttonContainer {
  text-align: center;
  display: flex;
  gap: var(--spacing);
  justify-content: center;
  margin-top: var(--spacing);
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
  margin: var(--spacing);
}

.day {
  background-color: rgb(var(--foreground));
  color: rgb(var(--background));
  padding: var(--spacing) 0;
  border-radius: 10px;
  text-align: center;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
  margin: var(--spacing) auto;
}

.arrows {
  cursor: pointer;
  padding: var(--spacing);
}

.rowContainerNoPadding {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.fieldGroup.responsiveColumn {
  flex-direction: row;
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  
} 


.field input,
.field textarea {
  border-radius: 10px;
  padding: 10px;
  font-size: var(--fontSize);
}

.zebra {
  opacity: 0.6;
}

.zebra.selected,
.zebra:hover {
  opacity: 1;
  cursor: pointer;
}

 path,
 line,
 g {
  stroke: rgb(var(--foreground));
}

 polygon {
  fill: rgb(var(--foreground));
}

.figure path{
  cursor: pointer;
}

.slotsContainer {
  min-height: 50vh;
}

@media screen and (max-width: 600px) {
  .grid, .grid2 {
    grid-template-columns: 1fr;
  }

  .gridItem {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .flexContainer {
    flex-direction: column;
  }

  .fieldGroup.responsiveColumn {
    flex-direction: column;
  }
}
