.container {
  padding: var(--spacing);
  width: var(--desktopWidth);
  margin: auto;
  font-size: var(--fontSize);
}

.margin {
  margin: var(--spacing);
}

.center {
  text-align: center;
}

.heading {
  margin: var(--spacing) auto;
}

.giftCard {
  position: relative;
  cursor: pointer;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.giftCard img {
  border-radius: 25px;
  width: 100%;
}

.icon {
  stroke: rgba(var(--foreground));
  fill: rgba(var(--foreground));
  margin-right: var(--spacing);
}

.radioBox {
  position: absolute;
  z-index: 1;
  right: -15px;
  top: -5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--spacing);
  row-gap: var(--spacing);
  margin-bottom: var(--spacing);
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--spacing);
  row-gap: var(--spacing);
  margin: var(--spacing) auto;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.fieldGroup.responsiveColumn {
  flex-direction: row;
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.checkBoxGroup {
  display: flex;
  gap: var(--spacing);
  align-items: center;
}


.field input,
.field textarea {
  border-radius: 10px;
  padding: 10px;
  font-size: var(--fontSize);
}

.buttonContainer {
  text-align: center;
  display: flex;
  gap: var(--spacing);
  justify-content: center;
  margin-top: var(--spacing);
}

@media screen and (max-width: 600px) {
  .grid, .grid2 {
    grid-template-columns: 1fr;
  }

  .fieldGroup.responsiveColumn {
    flex-direction: column;
  }
}

