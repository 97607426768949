.radioBox {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: rgba(var(--background));
  color: rgba(var(--foreground));
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(var(--foreground));
  cursor: pointer;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}