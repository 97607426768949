.cover {
  position: relative;
  min-height: 100vh;
  background-color: rgb(var(--background));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  will-change: transform; 
  transition-duration: 0s;
  transform: translate3d(0%, 0px, 0px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.small {
  min-height: 70vh;
}

.heading {
  font-family: heading;
  text-align: center;
  font-size: var(--headingSize);
}

.description {
  margin: 0 auto;
  text-align: center;
  width: var(--desktopWidth);
}

@media (max-width: 600px) {
  .cover {
    padding-top: var(--spacing);
    padding-bottom: var(--spacing);
    min-height: 70vh;
  }
}