.container {
  position: relative;
  border-radius: 10px;
  padding: var(--spacing);
  cursor: pointer;
  background-color: rgba(var(--foreground));
  color: rgba(var(--background));
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.radioBox {
  position: absolute;
  z-index: 1;
  right: -15px;
  top: -5px;
}

.title {
  margin-bottom: var(--spacing);
  flex-direction: column;
  display: flex;
}

.description {
  font-size: large;
}