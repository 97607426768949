.container {
  background-color: rgba(var(--foreground));
  color: rgba(var(--background));
  border: none;
  outline: none;
  padding: 1rem var(--spacing);
  border-radius: 10px;
  height: var(--spacing);
  line-height: 0.1rem;
  cursor: pointer;
  font-family: heading;
  font-size: var(--fontSize);
  vertical-align: middle;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.container:hover {
  background-color: rgba(var(--foreground), 0.9);
}

.container:disabled {
  background-color: rgba(var(--foreground), 0.5);
}