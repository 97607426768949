.container {
  position: relative;
  margin: var(--spacing) 0;
}

.time { 
  cursor: pointer;
  height: 30px;
  position: relative;
}

.slot {
  cursor: pointer;
  position: absolute;
  color: rgb(var(--background));
  border-radius: 10px;
  padding: 0 var(--spacing);
  bottom: 3px;
  right: 15px;
  left: 100px;
  z-index: 10;
  box-sizing: border-box;
}

.potentialSlot {
  cursor: pointer;
  position: absolute;
  background-color: rgb(var(--foreground));
  color: rgb(var(--background));
  border-radius: 10px;
  padding: 0 var(--spacing);
  top: 0px;
  bottom: 3px;
  right: 15px;
  left: 100px;
  z-index: 0;
  box-sizing: border-box;
}

.timeLabel {
  position: absolute;
  height: 30px;
  width: 100%;
}

.slot svg {
  cursor: pointer;
}

.slot path {
  stroke: none;
}

.movingSlot {
  height: 30px;
  position: relative;
  text-align: right;
}
