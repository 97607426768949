.hidden {
  display: none;  
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(var(--background));
  color: rgba(var(--foreground));
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 4rem;
  font-size: var(--fontSize);
  top: 0;
}

.group {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  gap: 2rem;
}

.group.left {
  justify-content: flex-start;
}

.group.right {
  justify-content: flex-end;
}

.nav svg {
  fill: rgba(var(--foreground));
  height: 40px;
}

.hidden {
  display: flex;
}

.mobile {
  display: none;
  font-size: medium;
}

.logo {
  margin: auto var(--spacing);
}

.hamburger {
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  
}

.hamburger div {
  background-color: rgb(var(--foreground));
  height: 2px;
}

.slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--background));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.slider.open {
  transform: translateX(0);
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 600px) {
  .nav {
    padding: 0;
  }
  
  .nav svg {
    height: 30px;
  }

  .hidden {
    display: none;
  }

  .mobile {
    display: flex;
  }
  
}